import React from "react";
import {
  Box,
  Flex,
  Stack,
  Text,
  Link,
  Image,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { FaTwitter, FaFacebook, FaPinterest, FaYoutube } from "react-icons/fa";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Footer = () => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      mt={16} // Add margin top here
      width="100%"
    >
      <Flex
        direction="column"
        maxW={"1200px"}
        mx="auto"
        px={4}
        py={10}
        width="100%"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          align="center"
          mb={8}
        >
          <RouterLink to="https://www.learnitalianpod.com/" target="_blank">
            <Image
              src="/lip-logo-footer.png"
              alt="ItalianChat Logo"
              height="50px"
              mb={{ base: 4, md: 0 }}
            />
          </RouterLink>
          <Stack direction={"row"} spacing={6}>
            <Link
              as={RouterLink}
              to="https://www.learnitalianpod.com/contact/"
              target="_blank"
            >
              Support
            </Link>
            <Link
              as={RouterLink}
              to="https://www.learnitalianpod.com/privacy-policy/"
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              as={RouterLink}
              to="https://www.learnitalianpod.com/terms-of-use/"
              target="_blank"
            >
              Terms
            </Link>
            <Link
              as={RouterLink}
              to="https://www.learnitalianpod.com/about-us-learnitalianpod/"
              target="_blank"
            >
              About
            </Link>
            <Link
              as={RouterLink}
              to="https://www.learnitalianpod.com/#italian-way-of-life-blog"
              target="_blank"
            >
              Blog
            </Link>
          </Stack>
        </Flex>

        <Box
          borderTopWidth={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          pt={8}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
          >
            <Text mb={{ base: 4, md: 0 }}>
              © 2025 ItalianChat. All rights reserved
            </Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton
                label={"Facebook"}
                href={"https://www.facebook.com/LearnItalianPod"}
              >
                <FaFacebook />
              </SocialButton>
              <SocialButton
                label={"Twitter"}
                href={"https://x.com/learnitalianpod"}
              >
                <FaTwitter />
              </SocialButton>
              <SocialButton
                label={"Pinterest"}
                href={"https://www.pinterest.com/learnitalianpod/"}
              >
                <FaPinterest />
              </SocialButton>
              <SocialButton
                label={"YouTube"}
                href={"https://www.youtube.com/@LearnItalianPod"}
              >
                <FaYoutube />
              </SocialButton>
            </Stack>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
